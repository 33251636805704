import React from 'react';
import './styles.css';
import { NavLink as Link } from 'react-router-dom';
import { FaBug, FaFish } from 'react-icons/fa'
type NavbarProps = {
}

const Navbar: React.FunctionComponent<NavbarProps> = () => {
  const routes = [
    { path:'/fishes', name:'fishes', icon: FaFish },
    { path: '/insects', name: 'insects', icon: FaBug }
  ];

  return (
    <div className="cc-navbar">
      <div className="nav-container">
        <nav>
          {
            routes.map((route, key) => (
                <Link key={key} to={route.path} className="nav-link" activeClassName="selected-page">
                  <div className="nav-link-label">{route.name}</div>
                  <div className="nav-link-icon"><route.icon/></div>
                </Link>
            ))
          }

        </nav>
      </div>
      <div className="scallop"></div>
    </div>
  )
}

export default Navbar;