import Axios from 'axios';
import { Fish, Insect } from 'types';

const API = process.env.REACT_APP_API || '';

export const getFishes = (): Promise<Fish[]> => Axios.get(`${API}/data/fishes.json`).then(result => result.data);

export const getInsects = (): Promise<Insect[]> => Axios.get(`${API}/data/insects.json`).then(result => result.data);

export const IMG = `${API}/images`;

export default {
  getFishes,
  getInsects,
  IMG
}